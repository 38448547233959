import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import type { IDynamicList, IList, TDynamicListLoader } from './types';

// TODO: dynamic loading logic
@injectable()
class DynamicList<T, List extends IList<T> = IList<T>> implements IDynamicList<T, List> {
  @observable
  isLoading: boolean;

  @observable
  private _list: List;

  constructor(list: List, private readonly _loader: TDynamicListLoader<T>) {
    this._list = list;
    makeObservable(this);
  }

  getList(): List {
    return this._list;
  }

  @action
  async more(params: Parameters<TDynamicListLoader<T>>[0]): Promise<void> {
    try {
      this.isLoading = true;
      const result = await this._loader(params);
      this._list.setItems(result.results);
    } finally {
      this.isLoading = false;
    }
  }
}

export default DynamicList;
