import { inject, injectable } from 'inversify';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { EGlobalDIKeys } from 'core/di/keys';
import type { IListMapModel } from 'models/List/types';
import type { IListMapFactoryService } from 'services/list/types';
import { IPaginationRequestOptions } from 'api/apiServices/types';
import type { IAsyncListController, IAsyncListControllerSettings } from './types';

@injectable()
class AsyncListController<Item, Filter extends IPaginationRequestOptions>
  implements IAsyncListController<Item, Filter>
{
  private _list: IListMapModel<Item>;

  @observable
  private _filters: Filter;

  @observable
  total: number;

  private _loader: IAsyncListControllerSettings<Item, Filter>['loader'];

  constructor(
    @inject(EGlobalDIKeys.ListMapModelFactoryService)
    private _listFactory: IListMapFactoryService,

    { initialFilters = {}, loader, byKey }: IAsyncListControllerSettings<Item, Filter>,
  ) {
    // this._paginationCtrl = this._paginationCtrlFactory({ history, initialFilters });
    this._list = this._listFactory.create<Item>({ items: [] as Item[], byKey });
    this._filters = initialFilters;
    this._loader = loader;
    this._registerReactions();

    makeObservable(this);
  }

  private _registerReactions = () => {
    reaction(
      () => this._filters,
      () => {
        this.load();
      },
    );
  };

  @computed
  get items() {
    return this._list.toArray();
  }

  getItem(key: string | number | symbol) {
    return this._list.getItem(key);
  }

  @action
  setItems(items: Item[]) {
    return this._list.setItems(items);
  }

  @action
  changeFilter = (filter: Partial<Filter>) => {
    this._filters = { ...this._filters, ...filter };
  };

  @computed
  get filters() {
    return this._filters;
  }

  async load() {
    const result = await this._loader(this._filters);
    runInAction(() => {
      this.total = result.count;
    });
    this.setItems(result.results);
  }
}

export default AsyncListController;
