export namespace NTenderTestIds {
  export const TENDER_MODAL_UPDATE_TENDER = 'TENDER_MODAL_UPDATE_TENDER';
  export const TENDER_MODAL_CHANGE_OWNER = 'TENDER_MODAL_CHANGE_OWNER';
  export const TENDER_MODAL_UPDATE_TENDER_REASON = 'TENDER_MODAL_UPDATE_TENDER_REASON';

  export const TENDER_ACCREDITATION_ALERTS_REQUEST =
    'TENDER_ACCREDITATION_ALERTS_REQUEST';
  export const TENDER_ACCREDITATION_ALERTS_PENDING =
    'TENDER_ACCREDITATION_ALERTS_PENDING';
  export const TENDER_ACCREDITATION_ALERTS_EXPIRING =
    'TENDER_ACCREDITATION_ALERTS_EXPIRING';

  export const SORTING_TENDER_DATE = 'SORTING_TENDER_DATE';
  export const COMPANY_ACCESS_LIST = 'COMPANY_ACCESS_LIST';
  export const TENDERS_FILTER_OPEN_DRAWER = 'TENDERS_FILTER_OPEN_DRAWER';
  export const TENDERS_FILTER_DRAWER_PERSONAL = 'TENDERS_FILTER_DRAWER_PERSONAL';
  export const TENDERS_FILTER_DRAWER_TYPE = 'TENDERS_FILTER_DRAWER_TYPE';
  export const TENDERS_FILTER_DRAWER_STATUSES = 'TENDERS_FILTER_DRAWER_STATUSES';
  export const TENDERS_FILTER_DRAWER_REGIONS = 'TENDERS_FILTER_DRAWER_REGIONS';
  export const TENDERS_FILTER_DRAWER_PROJECTS = 'TENDERS_FILTER_DRAWER_PROJECTS';
  export const TENDERS_FILTER_DRAWER_WORK_KINDS = 'TENDERS_FILTER_DRAWER_WORK_KINDS';
  export const TENDERS_FILTER_DRAWER_MATERIALS = 'TENDERS_FILTER_DRAWER_MATERIALS';
  export const TENDERS_FILTER_DRAWER_OFFERS_END_DATE =
    'TENDERS_FILTER_DRAWER_OFFERS_END_DATE';
  export const TENDERS_FILTER_DRAWER_PUBLICATION_DATE =
    'TENDERS_FILTER_DRAWER_PUBLICATION_DATE';
  export const TENDERS_FILTER_DRAWER_FINISH_DATE = 'TENDERS_FILTER_DRAWER_FINISH_DATE';
  export const TENDERS_FILTER_DRAWER_EMPLOYEE = 'TENDERS_FILTER_DRAWER_EMPLOYEE';

  export const TENDERS_MAIN_FILTER_SEARCH = 'TENDERS_MAIN_FILTER_SEARCH';
  export const TENDER_FILTER_TYPE = 'TENDER_FILTER_TYPE';

  export const TENDERS_QUICK_FILTER_CLASS_NAME = 'TENDERS_QUICK_FILTER_CLASS_NAME';
  export const TENDERS_QUICK_FILTER_ITEM_CLASS_NAME =
    'TENDERS_QUICK_FILTER_ITEM_CLASS_NAME';
  export const TENDERS_LIST_CLASS_NAME = 'TENDERS_LIST_CLASS_NAME';
  export const TENDERS_LIST_ITEM_CLASS_NAME = 'TENDERS_LIST_ITEM_CLASS_NAME';
  export const TENDERS_LIST_STATE = 'TENDERS_LIST_STATE';
  export const TENDERS_LIST_REBIDDING_TAG = 'TENDERS_LIST_REBIDDING_TAG';

  export const CANCEL_LOT_MODAL = 'CANCEL_LOT_MODAL';
  export const CANCEL_LOT_MODAL_CHECKBOX = 'CANCEL_LOT_MODAL_CHECKBOX';
  export const CANCEL_LOT_MODAL_COMMENTS = 'CANCEL_LOT_MODAL_COMMENTS';

  export enum DOCUMENTS_TYPE {
    DESIGN_SPECIFICATION = 'designSpecification',
    PLANNING_DOCUMENTATION = 'planningDocumentation',
    DRAFT_OF_CONTRACT = 'draftOfContract',
    ADDITIONAL_DOCUMENTATION = 'additionalDocumentation',
  }

  // button action and all items
  export const COPY_TENDER_TO_DRAFT = 'COPY_TENDER_TO_DRAFT';
  export const BUTTON_PLUS = 'BUTTON_PLUS';
  export const TENDER_PBE_ADD_BY_PLUS = 'TENDER_PBE_ADD_BY_PLUS';
  export const TENDER_CREATE_BUTTON = 'TENDER_CREATE_BUTTON';
  export const TENDER_CREATE_MODAL = 'TENDER_CREATE_MODAL';
  export const PAGE_TITLE = 'pageTitle';
  export const TENDER_NAME = 'TENDER_NAME';
  export const TENDER_BASE_PRICE_TYPE = 'TENDER_BASE_PRICE_TYPE';
  export const TENDER_HIDE_ESTIMATED_PRICE_FROM_PARTICIPANTS =
    'TENDER_HIDE_ESTIMATED_PRICE_FROM_PARTICIPANTS';
  export const TENDER_TEST_ID = 'TENDER_TEST_ID';
  export const TENDER_SKELETON_TEST_ID = 'TENDER_SKELETON_TEST_ID';
  export const PUBLISH_BUTTON = 'publishButton';
  export const PUBLISH_REASON = 'PUBLISH_REASON';

  export const INFO_ITEM_MEDIUM_INFO = 'INFO_ITEM_MEDIUM_INFO';

  export const TENDER_CONTACTS_PRICE_VIEW = 'TENDER_CONTACTS_PRICE_VIEW';
  export const TENDER_CONTACTS_PRICE = 'TENDER_CONTACTS_PRICE';

  export const TENDER_CONTACTS_AUTHORITIES = 'TENDER_CONTACTS_AUTHORITIES';
  export const TENDER_CONTACTS_OWNER_NAME = 'TENDER_CONTACTS_OWNER_NAME';

  export const TENDER_CONTACTS_OWNER_NAME_CHANGE = 'TENDER_CONTACTS_OWNER_NAME_CHANGE';
  export const TENDER_CONTACTS_EMAIL = 'TENDER_CONTACTS_EMAIL';
  export const TENDER_CONTACTS_PHONE = 'TENDER_CONTACTS_PHONE';
  export const TENDER_CONTACTS_POSITION = 'TENDER_CONTACTS_POSITION';
  export const TENDER_CONTACTS_ADDITIONAL_CONTACTS =
    'TENDER_CONTACTS_ADDITIONAL_CONTACTS';

  export const TENDER_PURCHASE_DATE_SWITCH = 'TENDER_PURCHASE_DATE_SWITCH';
  export const TENDER_DATES_TOGGLE_BUTTON = 'TENDER_DATES_TOGGLE_BUTTON';

  export const TENDER_ADD_TO_FAVORITE = 'TENDER_FAVORITE';
  export const TENDER_REMOVE_FROM_FAVORITE = 'TENDER_REMOVE_FROM_FAVORITE';
  export const TENDER_PUBLICATION = 'TENDER_PUBLICATION';
  export const TENDER_SHORT_NAME = 'TENDER_SHORT_NAME';
  export const TENDER_PRIVATE = 'TENDER_PRIVATE';
  export const TENDER_DIFFERENT_VOLUME = 'TENDER_DIFFERENT_VOLUME';
  export const TENDER_SPECIFICATION_PROPOSITION = 'TENDER_SPECIFICATION_PROPOSITION';
  export const TENDER_ALTERNATIVE_OFFERS = 'TENDER_ALTERNATIVE_OFFERS';
  export const TENDER_SUPPLIER_STATUS_REQUIRED = 'TENDER_SUPPLIER_STATUS_REQUIRED';
  export const TENDER_FOREIGN_CURRENCY = 'TENDER_FOREIGN_CURRENCY';
  export const TENDER_MANUFACTURER_REQUIRED = 'TENDER_MANUFACTURER_REQUIRED';
  export const TENDER_MAIN_SPECIALIZATION = 'TENDER_MAIN_SPECIALIZATION';
  export const TENDER_SPECIALIZATIONS = 'TENDER_SPECIALIZATIONS';
  // ----
  export const TENDER_COLLAPSE_ITEM_BASE_INFO = 'TENDER_COLLAPSE_ITEM_BASE_INFO';
  export const TENDER_COLLAPSE_ITEM_PURCHASE_INFO = 'TENDER_COLLAPSE_ITEM_PURCHASE_INFO';
  export const TENDER_COLLAPSE_ITEM_DOCUMENTS = 'TENDER_COLLAPSE_ITEM_DOCUMENTS';
  export const TENDER_COLLAPSE_ITEM_LOTS = 'TENDER_COLLAPSE_ITEM_LOTS';
  export const TENDER_COLLAPSE_ITEM_ADDITIONAL_INFO =
    'TENDER_COLLAPSE_ITEM_ADDITIONAL_INFO';
  // -----

  export const TENDER_CONTENT = 'TENDER_CONTENT';

  export const TENDER_EMPLOYEE_BUTTONS = 'TENDER_EMPLOYEE_BUTTONS';
  export const TENDER_SAVE_BUTTON = 'TENDER_SAVE_BUTTON';
  export const TENDER_REMOVE_BUTTON = 'TENDER_REMOVE_BUTTON';

  export const TENDER_PROJECTS = 'TENDER_PROJECTS';
  export const PROJECTS = 'projects';

  export const SORT_DESCENDING_ALT = 'SORT_DESCENDING_ALT';
  export const SORT_ASCENDING_ALT = 'SORT_ASCENDING_ALT';

  export const TENDER_PURCHASE_TYPE_TITLE = 'TENDER_PURCHASE_TYPE_TITLE';
  export const TENDER_PURCHASE_REGION = 'TENDER_PURCHASE_REGION';
  export const TENDER_PURCHASE_ACCESS_TYPE = 'TENDER_PURCHASE_ACCESS_TYPE';
  export const TENDER_PURCHASE_VAT_TITLE = 'TENDER_PURCHASE_VAT_TITLE';
  export const TENDER_PURCHASE_VAT = 'TENDER_PURCHASE_VAT';
  export const TENDER_LOTS = 'TENDER_LOTS';
  export const TENDER_LOT = 'TENDER_LOT';
  export const TENDER_LOT_NAME = 'TENDER_LOT_NAME';
  export const TENDER_LOT_PRICE_CONTAINER = 'TENDER_LOT_PRICE_CONTAINER';
  export const TENDER_LOT_BASE_PRICE = 'TENDER_LOT_BASE_PRICE';
  export const TENDER_LOT_BASE_PRICE_TYPE = 'TENDER_LOT_BASE_PRICE_TYPE';
  export const TENDER_LOT_CONSTRUCTIONS = 'TENDER_LOT_CONSTRUCTIONS';
  export const TENDER_LOT_PBE = 'TENDER_LOT_PBE';
  export const TENDER_LOT_PBE_TABLE = 'TENDER_LOT_PBE_TABLE';
  export const TENDER_LOT_SELECT_PBE_ROW = 'SELECT_PBE_ROW';
  export const TENDER_LOT_CONFIRM_REMOVE_PBE_ROW = 'TENDER_LOT_CONFIRM_REMOVE_PBE_ROW';
  export const TENDER_LOT_HIDE_ESTIMATED_PRICE_FROM_PARTICIPANTS =
    'TENDER_LOT_HIDE_ESTIMATED_PRICE_FROM_PARTICIPANTS';
  export const TENDER_LOT_PRICE_OFFER_FILLINGS = 'TENDER_LOT_PRICE_OFFER_FILLINGS';
  export const TENDER_LOT_PRICE_OFFER_FILLINGS_ALL_PRICES =
    'TENDER_LOT_PRICE_OFFER_FILLINGS_ALL_PRICES';
  export const TENDER_LOT_PRICE_OFFER_FILLINGS_BEST_PRICE =
    'TENDER_LOT_PRICE_OFFER_FILLINGS_BEST_PRICE';
  export const TENDER_LOT_PRICE_OFFER_FILLINGS_RATING =
    'TENDER_LOT_PRICE_OFFER_FILLINGS_RATING';
  export const TENDER_LOT_PRICE_OFFER_FILLINGS_COMPETITOR =
    'TENDER_LOT_PRICE_OFFER_FILLINGS_COMPETITOR';
  export const TENDER_WINNER_RESOLVE_TYPE = 'TENDER_WINNER_RESOLVE_TYPE';
  export const TENDER_LOT_AUCTION_MODE = 'TENDER_LOT_AUCTION_MODE';
  export const TENDER_LOT_MINIMAL_AUCTION_STEP = 'TENDER_LOT_MINIMAL_AUCTION_STEP';
  export const TENDER_REBIDDING_COMPARABLE_PRICE = 'TENDER_REBIDDING_COMPARABLE_PRICE';
  export const TENDER_LOT_REBIDDING_AUTO_PROLONGATION =
    'TENDER_LOT_REBIDDING_AUTO_PROLONGATION';
  export const TENDER_LOT_REBIDDING_PROLONGATION_TIME =
    'TENDER_LOT_REBIDDING_PROLONGATION_TIME';
  export const TENDER_LOT_REBIDDING_PROLONGATION_MAX_AMOUNT =
    'TENDER_LOT_REBIDDING_PROLONGATION_MAX_AMOUNT';
  export const TENDER_LOT_SHOW_ROW_AMOUNT = 'TENDER_LOT_SHOW_ROW_AMOUNT';
  export const TENDER_LOT_SHOW_ROW_MANUFACTURER = 'TENDER_LOT_SHOW_ROW_MANUFACTURER';
  export const TENDER_LOT_SHOW_ROW_VOLUME = 'TENDER_LOT_SHOW_ROW_VOLUME';
  export const TENDER_LOT_TRANSPORT_COSTS = 'TENDER_LOT_TRANSPORT_COSTS';
  export const TENDER_LOT_ADD_MATERIAL = 'TENDER_LOT_ADD_MATERIAL';
  export const TENDER_LOT_REMOVE_MATERIAL = 'TENDER_LOT_REMOVE_MATERIAL';
  export const TENDER_LOT_REMOVE = 'TENDER_LOT_REMOVE';
  export const TENDER_LOT_ADD = 'TENDER_LOT_ADD';
  export const TENDER_LOT_CHANGE_ROWS_ORDER = 'TENDER_LOT_CHANGE_ROWS_ORDER';
  export const TENDER_LOT_EXPORT_XLSX = 'TENDER_LOT_EXPORT_XLSX';
  export const TENDER_LOT_IMPORT_XLSX = 'TENDER_LOT_IMPORT_XLSX';

  export const TENDER_TERMS_OF_DELIVERY = 'TENDER_TERMS_OF_DELIVERY';
  export const TENDER_DELIVERY_TIME_TYPE = 'TENDER_DELIVERY_TIME_TYPE';
  export const TENDER_DELIVERY_TIME_FREE_TO_EDIT = 'TENDER_DELIVERY_TIME_FREE_TO_EDIT';
  export const TENDER_DELIVERY_NOTES = 'TENDER_DELIVERY_NOTES';
  export const TENDER_WARRANTY_PERIOD = 'TENDER_WARRANTY_PERIOD';
  export const TENDER_WARRANTY_PERIOD_FREE_TO_EDIT =
    'TENDER_WARRANTY_PERIOD_FREE_TO_EDIT';
  export const TENDER_PARTICIPANT_DOCUMENTS_LIST = 'TENDER_PARTICIPANT_DOCUMENTS_LIST';
  export const TENDER_PAYMENT_TERM_TYPE = 'TENDER_PAYMENT_TERM_TYPE';
  export const TENDER_PARTICIPANT_CAN_OFFER_PAYMENT_TERMS =
    'TENDER_PARTICIPANT_CAN_OFFER_PAYMENT_TERMS';
  export const TENDER_PAYMENT_DEFERMENT = 'TENDER_PAYMENT_DEFERMENT';
  export const TENDER_PARTICIPANT_CAN_OFFER_PAYMENT_DEFERMENT =
    'TENDER_PARTICIPANT_CAN_OFFER_PAYMENT_DEFERMENT';
  export const TENDER_MULTISTAGE_ORGANIZER_LIST = 'TENDER_MULTISTAGE_ORGANIZER_LIST';
  export const TENDER_MULTISTAGE_ORGANIZER_ADD_BUTTON =
    'TENDER_MULTISTAGE_ORGANIZER_ADD_BUTTON';
  export const TENDER_MULTISTAGE_ORGANIZER_CONDITION =
    'TENDER_MULTISTAGE_ORGANIZER_CONDITION';
  export const DELETE_MULTISTAGE_CONDITION_ICON = 'DELETE_MULTISTAGE_CONDITION_ICON';
  export const TENDER_MULTISTAGE_ORGANIZER_INPUT_STAGE =
    'TENDER_MULTISTAGE_ORGANIZER_INPUT_STAGE';
  export const TENDER_MULTISTAGE_ORGANIZER_INPUT_PAYMENT_PERIOD =
    'TENDER_MULTISTAGE_ORGANIZER_INPUT_PAYMENT_PERIOD';
  export const TENDER_MULTISTAGE_ORGANIZER_INPUT_PAYMENT_AMOUNT_PERCENTAGE =
    'TENDER_MULTISTAGE_ORGANIZER_INPUT_PAYMENT_AMOUNT_PERCENTAGE';
  export const TENDER_PAYMENT_TERM_ADDITIONAL_INFORMATION =
    'TENDER_PAYMENT_TERM_ADDITIONAL_INFORMATION';
  export const TENDER_TOTAL_BASE_PRICE = 'TENDER_TOTAL_BASE_PRICE';
  export const TENDER_OBJECTIVE_VALUE = 'TENDER_OBJECTIVE_VALUE';
  export const TENDER_MARKET_AREA = 'TENDER_MARKET_AREA';
  export const TENDER_ESTIMATED_VOLUME = 'TENDER_ESTIMATED_VOLUME';
  export const TENDER_PREPAYMENT = 'TENDER_PREPAYMENT';
  export const TENDER_WARRANTY_PERIOD_SECURITY = 'TENDER_WARRANTY_PERIOD_SECURITY';
  export const TENDER_ADDITIONAL_INFO = 'TENDER_ADDITIONAL_INFO';
  export const TENDER_CONTRACT_BRIEF = 'TENDER_CONTRACT_BRIEF';
  export const TENDER_REQUIREMENTS_AND_CONFIRMATIONS =
    'TENDER_REQUIREMENTS_AND_CONFIRMATIONS';
  export const TENDER_PARTICIPANT_REQUIREMENTS = 'TENDER_PARTICIPANT_REQUIREMENTS';
  export const TENDER_CONSTRUCTION_SITE_ENTER = 'TENDER_CONSTRUCTION_SITE_ENTER';
  export const TENDER_TOTAL_COMPLETION_TIME = 'TENDER_TOTAL_COMPLETION_TIME';
  export const TENDER_ADD_CRITERIA_BUTTON = 'TENDER_ADD_CRITERIA_BUTTON';
  export const TENDER_IMPORT_CRITERIA_BUTTON = 'TENDER_IMPORT_CRITERIA_BUTTON';
  export const TENDER_EXPORT_CRITERIA_BUTTON = 'TENDER_EXPORT_CRITERIA_BUTTON';
  export const TENDER_CRITERIA_MODAL = 'TENDER_CRITERIA_MODAL';
  export const TENDER_CRITERIA_MODAL_IS_REQUIRED = 'TENDER_CRITERIA_MODAL_ISR_EQUIRED';
  export const TENDER_CRITERIA_MODAL_NAME = 'TENDER_CRITERIA_MODAL_NAME';
  export const TENDER_CRITERIA_MODAL_TYPE = 'TENDER_CRITERIA_MODAL_TYPE';
  export const TENDER_CRITERIA_MODAL_UNIT = 'TENDER_CRITERIA_MODAL_UNIT';
  export const TENDER_CRITERIA_MODAL_VARIANTS = 'TENDER_CRITERIA_MODAL_VARIANTS';
  export const TENDER_CRITERIA_OK_BUTTON = 'TENDER_CRITERIA_OK_BUTTON';
  export const TENDER_VIEW_COLLAPSE_BUTTON = 'TENDER_VIEW_COLLAPSE_BUTTON';
  export const TENDER_FORMULA_PRICING_CHECKBOX = 'TENDER_FORMULA_PRICING_CHECKBOX';
  export const ADD_BUTTON_ICON = 'ADD_BUTTON_ICON';

  // Additional Info
  export const TENDER_INDICATORS_ICON = 'TENDER_INDICATORS_ICON';
  export const TENDER_INDICATORS_TOTAL_BASE_PRICE = 'TENDER_INDICATORS_TOTAL_BASE_PRICE';
  export const TENDER_INDICATORS_OBJECTIVES_VALUES =
    'TENDER_INDICATORS_OBJECTIVES_VALUES';
  export const TENDER_INDICATORS_ESTIMATED_VOLUME_VIEW =
    'TENDER_INDICATORS_ESTIMATED_VOLUME_VIEW';
  export const TENDER_INDICATORS_MARKET_AREA_VIEW = 'TENDER_INDICATORS_MARKET_AREA_VIEW';

  // Documents collapse ids
  export const TENDER_DOCUMENT_DESIGN_SPECIFICATION =
    'TENDER_DOCUMENT_DESIGN_SPECIFICATION';
  export const TENDER_DOCUMENT_PLANNING_DOCUMENTATION =
    'TENDER_DOCUMENT_PLANNING_DOCUMENTATION';
  export const TENDER_DOCUMENT_DRAFT_OF_CONTRACT = 'TENDER_DOCUMENT_DRAFT_OF_CONTRACT';
  export const TENDER_DOCUMENT_ADDITIONAL_DOCUMENTATION =
    'TENDER_DOCUMENT_ADDITIONAL_DOCUMENTATION';
  export const TENDER_DOCUMENTS_IDS: Record<DOCUMENTS_TYPE, string> = {
    [DOCUMENTS_TYPE.ADDITIONAL_DOCUMENTATION]: TENDER_DOCUMENT_ADDITIONAL_DOCUMENTATION,
    [DOCUMENTS_TYPE.DESIGN_SPECIFICATION]: TENDER_DOCUMENT_DESIGN_SPECIFICATION,
    [DOCUMENTS_TYPE.PLANNING_DOCUMENTATION]: TENDER_DOCUMENT_PLANNING_DOCUMENTATION,
    [DOCUMENTS_TYPE.DRAFT_OF_CONTRACT]: TENDER_DOCUMENT_DRAFT_OF_CONTRACT,
  };

  // Additional collapse ids
  export const TENDER_ADDITIONAL_INFO_CRITERIA = 'TENDER_ADDITIONAL_INFO_CRITERIA';
  export const TENDER_ADDITIONAL_INFO_CRITERIA_TABLE =
    'TENDER_ADDITIONAL_INFO_CRITERIA_TABLE';
  export const TENDER_ADDITIONAL_INFO_NOTES = 'TENDER_ADDITIONAL_INFO_NOTES';

  export const TENDER_WHITE_LIST_PAGINATION = 'TENDER_WHITE_LIST_PAGINATION';
  export const TENDER_WHITE_LIST_SEARCH = 'TENDER_WHITE_LIST_SEARCH';
  export const TENDER_WHITE_LIST_TABLE = 'TENDER_WHITE_LIST_TABLE';

  export const TENDER_STATUS = 'TENDER_STATUS';
  export const STATUSES_WIDGET_TITLE = 'STATUSES_WIDGET_TITLE';
  // Tender card
  export const TENDER_FIRST_PUBLICATION_DATE = 'TENDER_FIRST_PUBLICATION_DATE';
  export const TENDER_CARD_OPEN = 'TENDER_CARD_OPEN';
  export const TENDER_CARD_TYPE = 'TENDER_CARD_TYPE';
  export const TENDER_CARD_FINISH_DATE = 'TENDER_CARD_FINISH_DATE';
  export const TENDER_CARD_OPEN_MORE = 'TENDER_CARD_OPEN_MORE';
  export const TENDER_CARD_HAS_1C_UPDATES = 'TENDER_CARD_HAS_1C_UPDATES';
  export const TENDER_CARD_HAS_NEW_QUESTIONS = 'TENDER_CARD_HAS_NEW_QUESTIONS';
  export const TENDER_CARD_IS_PARTICIPANT = 'TENDER_CARD_IS_PARTICIPANT';
  export const TENDER_CARD_REGIONS = 'TENDER_CARD_REGIONS';
  export const SORTING_TENDERS = 'SORTING_TENDERS';
  export const TENDER_CREATED_DATE = 'TENDER_CREATED_DATE';
  export const TENDER_FINISH_DATE = 'TENDER_FINISH_DATE';
  export const TENDER_PROPOSALS_END_DATE = 'TENDER_PROPOSALS_END_DATE';
  export const TENDER_CONFIRMATION_END = 'TENDER_CONFIRMATION_END';

  // Select employee modal
  export const TENDER_EMPLOYEES = 'TENDER_EMPLOYEES';
  export const TENDER_EMPLOYEE_MODAL = 'TENDER_EMPLOYEE_MODAL';
  export const TENDER_EMPLOYEE_MODAL_SELECT = 'TENDER_EMPLOYEE_MODAL_SELECT';
  export const TENDER_EMPLOYEE_MODAL_TABLE = 'TENDER_EMPLOYEE_MODAL_TABLE';
  export const TENDER_EMPLOYEE_MODAL_CELL = 'TENDER_EMPLOYEE_MODAL_CELL';
  export const TENDER_EMPLOYEE_MODAL_ROW = 'TENDER_EMPLOYEE_MODAL_ROW';
  export const TENDER_EMPLOYEE_MODAL_SAVE_BUTTON = 'TENDER_EMPLOYEE_MODAL_SAVE_BUTTON';
  export const TENDER_EMPLOYEE_MODAL_FILTER_SEARCH =
    'TENDER_EMPLOYEE_MODAL_FILTER_SEARCH';
  export const TENDER_EMPLOYEE_MODAL_FILTER_ORGANIZATION =
    'TENDER_EMPLOYEE_MODAL_FILTER_ORGANIZATION';
  export const TENDER_EMPLOYEE_MODAL_FILTER_GROUP = 'TENDER_EMPLOYEE_MODAL_FILTER_GROUP';

  //Person
  export const TENDER_PERSON_CARD = 'TENDER_PERSON_CARD';

  export const TENDER_FINISH_MODAL = 'TENDER_FINISH_MODAL';
  export const TENDER_ACTIONS_BUTTON = 'TENDER_ACTIONS_BUTTON';
  export const TENDER_ACTIONS_BUTTON_CLONE = 'TENDER_ACTIONS_BUTTON_CLONE';
  export const TENDER_ACTIONS_BUTTON_FINISH_TENDER =
    'TENDER_ACTIONS_BUTTON_FINISH_TENDER';
  export const TENDER_ACTIONS_BUTTON_NEXT_STAGE = 'TENDER_ACTIONS_BUTTON_NEXT_STAGE';
  export const TENDER_ACTIONS_BUTTON_PREV_STAGE = 'TENDER_ACTIONS_BUTTON_PREV_STAGE';
  export const TENDER_ACTIONS_BUTTON_CHANGE_OWNER = 'TENDER_ACTIONS_BUTTON_CHANGE_OWNER';
  export const TENDER_ACTIONS_BUTTON_FAQ = 'TENDER_ACTIONS_BUTTON_FAQ';
  export const TENDER_ACTIONS_BUTTON_EDIT = 'TENDER_ACTIONS_BUTTON_EDIT';
  export const TENDER_ACTIONS_BUTTON_INVITE_BY_SYSTEM =
    'TENDER_ACTIONS_BUTTON_INVITE_BY_SYSTEM';
  export const TENDER_ACTIONS_BUTTON_INVITE_BY_EMAIL =
    'TENDER_ACTIONS_BUTTON_INVITE_BY_EMAIL';
  export const TENDER_ACTIONS_BUTTON_CANCEL_LOT = 'TENDER_ACTIONS_BUTTON_CANCEL_LOT';
  export const TENDER_ACTIONS_BUTTON_CLASSNAME = 'TENDER_ACTIONS_BUTTON_CLASSNAME';

  export const TENDER_PERSON_PERSONAL_DATA = 'TENDER_PERSON_PERSONAL_DATA';
  export const TENDER_PERSON_PERSONAL_PHONE = 'TENDER_PERSON_PERSONAL_PHONE';
  export const TENDER_PERSON_PERSONAL_EMAIL = 'TENDER_PERSON_PERSONAL_EMAIL';

  // Update tender modal
  export const TENDER_UPDATE_MODAL_SHOULD_UPDATE_PROPOSAL =
    'TENDER_UPDATE_MODAL_SHOULD_UPDATE_PROPOSAL';
  export const TENDER_UPDATE_MODAL_SHOULD_NOTIFY_CHECKBOX =
    'TENDER_UPDATE_MODAL_SHOULD_NOTIFY_CHECKBOX';

  // Change owner modal
  export const TENDER_CHANGE_OWNER_MODAL_ALERT = 'TENDER_CHANGE_OWNER_MODAL_ALERT';
  export const TENDER_CHANGE_OWNER_MODAL_SELECT = 'TENDER_CHANGE_OWNER_MODAL_SELECT';

  // Request clarification modal
  export const TENDER_REQUEST_CLARIFICATION_MODAL_TEXTAREA =
    'TENDER_REQUEST_CLARIFICATION_MODAL_TEXTAREA';
  export const TENDER_REQUEST_CLARIFICATION_MODAL_UPLOAD_BUTTON =
    'TENDER_REQUEST_CLARIFICATION_MODAL_UPLOAD_BUTTON';

  // Add/edit faq question modal
  export const TENDER_FAQ_QUESTION_MODAL_THEME_INPUT =
    'TENDER_FAQ_QUESTION_MODAL_THEME_INPUT';
  export const TENDER_FAQ_QUESTION_MODAL_QUESTION_TEXTAREA =
    'TENDER_FAQ_QUESTION_MODAL_QUESTION_TEXTAREA';
  export const TENDER_FAQ_QUESTION_MODAL_QUESTION_UPLOAD_BUTTON =
    'TENDER_FAQ_QUESTION_MODAL_QUESTION_UPLOAD_BUTTON';
  export const TENDER_FAQ_QUESTION_MODAL_ANSWER_TEXTAREA =
    'TENDER_FAQ_QUESTION_MODAL_ANSWER_TEXTAREA';
  export const TENDER_FAQ_QUESTION_MODAL_ANSWER_UPLOAD_BUTTON =
    'TENDER_FAQ_QUESTION_MODAL_ANSWER_UPLOAD_BUTTON';

  // ModalProposalDecision
  export const TENDER_MODAL_PROPOSAL_DECISION = 'TENDER_MODAL_PROPOSAL_DECISION';
  export const TENDER_MODAL_PROPOSAL_DECISION_SELECT_DECISION =
    'TENDER_MODAL_PROPOSAL_DECISION_SELECT_DECISION';
  export const TENDER_MODAL_PROPOSAL_DECISION_TEXTAREA_COMMENT =
    'TENDER_MODAL_PROPOSAL_DECISION_TEXTAREA_COMMENT';

  // ModalProtocols
  export const TENDER_MODAL_PROTOCOLS_INFOCARD_LINK =
    'TENDER_MODAL_PROTOCOLS_INFOCARD_LINK';
  export const TENDER_MODAL_PROTOCOLS_INFOCARD_LINK_WRAPPER =
    'TENDER_MODAL_PROTOCOLS_INFOCARD_LINK_WRAPPER';
  export const TENDER_MODAL_PROTOCOLS_PROTOCOL_LINK =
    'TENDER_MODAL_PROTOCOLS_PROTOCOL_LINK';

  // ModalFinishTender
  export const TENDER_FINISH_MODAL_INFOCARD = 'TENDER_FINISH_MODAL_INFOCARD';
  export const TENDER_FINISH_MODAL_PROTOCOL = 'TENDER_FINISH_MODAL_PROTOCOL';
  export const TENDER_FINISH_MODAL_PROTOCOL_ANNEXES =
    'TENDER_FINISH_MODAL_PROTOCOL_ANNEXES';

  // ModalMakeDecision
  export const MODAL_MAKE_DECISION_SELECT_OF_DECISION =
    'MODAL_MAKE_DECISION_SELECT_OF_DECISION';
  export const MODAL_MAKE_DECISION_SELECT_OF_PROPOSAL_DECISION =
    'MODAL_MAKE_DECISION_SELECT_OF_PROPOSAL_DECISION';
  export const MODAL_MAKE_DECISION_CHECKBOX_OF_PROPOSAL_DECISION =
    'MODAL_MAKE_DECISION_CHECKBOX_OF_PROPOSAL_DECISION';
  export const MODAL_MAKE_DECISION_ALERT = 'MODAL_MAKE_DECISION_ALERT';

  // ModalParticipants
  export const MODAL_INVITE_PARTICIPANTS_BY_SYSTEM =
    'MODAL_INVITE_PARTICIPANTS_BY_SYSTEM';
  export const MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_TABLE =
    'MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_TABLE';
  export const MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_FILTER_BY_NAME =
    'MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_FILTER_BY_NAME';
  export const MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_ACCREDITATION_CHECKBOX =
    'MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_ACCREDITATION_CHECKBOX';
  export const MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_SELECTED_PANEL =
    'MODAL_INVITE_PARTICIPANTS_BY_SYSTEM_SELECTED_PANEL';

  // LOTS
  export const LOTS_CONTROL = 'LOTS_CONTROL';
  export const CHOOSE_WINNER = 'CHOOSE_WINNER';
  export const CHANGE_WINNER = 'CHANGE_WINNER';
  export const LOTS_LIST_LOT = 'LOTS_LIST_LOT';
  export const LOTS_LIST_PROPOSAL_LOT = 'LOTS_LIST_PROPOSAL_LOT';
  export const LOT_LIST_PROPOSALS = 'LOT_LIST_PROPOSALS';
  export const LOT_TOTAL_AMOUNT = 'LOT_TOTAL_AMOUNT';
  export const OPEN_LOTS_CARD = 'OPEN_LOTS_CARD';
  export const CARD_ARROW_LINK = 'CARD_ARROW_LINK';
  export const PROPOSALS_TAB = 'PROPOSALS_TAB';
  export const PARTICIPANTS_TAB = 'PARTICIPANTS_TAB';
  export const APPROVED_PARTICIPANTS_TAB = 'APPROVED_PARTICIPANTS_TAB';
  export const APPROVED_PARTICIPANTS_TAB_COLLAPSE = 'APPROVED_PARTICIPANTS_TAB_COLLAPSE';
  export const APPROVED_PARTICIPANTS_TAB_TABLE = 'APPROVED_PARTICIPANTS_TAB_TABLE';
  export const APPROVED_PARTICIPANTS_TAB_COUNTER = 'APPROVED_PARTICIPANTS_TAB_COUNTER';
  export const NOT_APPROVED_PARTICIPANTS_TAB_COUNTER =
    'NOT_APPROVED_PARTICIPANTS_TAB_COUNTER';
  export const EXCLUDED_PARTICIPANTS_TAB_COUNTER = 'EXCLUDED_PARTICIPANTS_TAB_COUNTER';
  export const CONFIRM_PARTICIPATION_BTN = 'CONFIRM_PARTICIPATION_BTN';
  export const DECISION_COMMENT = 'DECISION_COMMENT';
  export const DOWNLOAD_RATING_BTN = 'DOWNLOAD_RATING_BTN';
  export const LOT_STATE_TITLE_TAG = 'LOT_STATE_TITLE_TAG';
  export const LOT_TITLE = 'LOT_TITLE';
  export const CREATION_PERIOD_VIEW = 'CREATION_PERIOD_VIEW';

  export const TENDER_PARTICIPATION_ALERT = 'TENDER_PARTICIPATION_ALERT';

  export const WHITE_LIST_PARTICIPANTS_TAB = 'WHITE_LIST_PARTICIPANTS_TAB';
  export const BLACK_LIST_PARTICIPANTS_TAB = 'BLACK_LIST_PARTICIPANTS_TAB';

  export const FILTER_WRAPPER = 'FILTER_WRAPPER';
  export const FILTER_WRAPPER_CLEAR_ALL = 'FILTER_WRAPPER_CLEAR_ALL';
  export const FILTER_WRAPPER_COLLAPSE = 'FILTER_WRAPPER_COLLAPSE';

  export const START_REBIDDING_OPEN = 'START_REBIDDING_OPEN';
  export const START_REBIDDING_DRAWER = 'START_REBIDDING_DRAWER';
  export const REBIDDING_INFO = 'REBIDDING_INFO';
  export const REBIDDING_INFO_POPOVER = 'REBIDDING_INFO_POPOVER';
  export const REBIDDING_PROPOSAL_ACCEPTANCE_START_DT =
    'REBIDDING_PROPOSAL_ACCEPTANCE_START_DT';
  export const WINNER_RESOLVE_TYPE = 'WINNER_RESOLVE_TYPE';
  export const PRICE_OFFER_FILLINGS_BITS_TITLES = 'PRICE_OFFER_FILLINGS_BITS_TITLES';
  export const REBIDDING_PROLONGATION_TIME = 'REBIDDING_PROLONGATION_TIME';
  export const REBIDDING_PROLONGATION_MAX_AMOUNT = 'REBIDDING_PROLONGATION_MAX_AMOUNT';
  export const AUCTION_MODE = 'AUCTION_MODE';
  export const REBIDDING_COMPARABLE_PRICE = 'REBIDDING_COMPARABLE_PRICE';

  export const START_REBIDDING_SELECTION_PAGE = 'START_REBIDDING_SELECTION_PAGE';
  export const START_REBIDDING_SELECTION_PAGE_FOOTER =
    'START_REBIDDING_SELECTION_PAGE_FOOTER';
  export const START_REBIDDING_SELECTION_PAGE_FOOTER_NEXT_BUTTON =
    'START_REBIDDING_SELECTION_PAGE_FOOTER_NEXT_BUTTON';
  export const START_REBIDDING_SELECTION_PAGE_LOTS_GROUP =
    'START_REBIDDING_SELECTION_PAGE_LOTS_GROUP';
  export const START_REBIDDING_HEADER = 'START_REBIDDING_HEADER';
  export const START_REBIDDING_HEADER_TITLE = 'START_REBIDDING_HEADER_TITLE';
  export const START_REBIDDING_HEADER_PREV_BUTTON = 'START_REBIDDING_HEADER_PREV_BUTTON';
  export const START_REBIDDING_SELECTION_PAGE_LOTS_ITEM =
    'START_REBIDDING_SELECTION_PAGE_LOTS_ITEM';
  export const START_REBIDDING_SELECTION_PAGE_LOTS_GROUP_REBIDDING =
    'START_REBIDDING_SELECTION_PAGE_LOTS_GROUP_REBIDDING';
  export const START_REBIDDING_SELECTION_PAGE_LOTS_GROUP_NON_REBIDDING =
    'START_REBIDDING_SELECTION_PAGE_LOTS_GROUP_NON_REBIDDING';
  export const START_REBIDDING_SELECTION_PAGE_LOTS_INFORMER =
    'START_REBIDDING_SELECTION_PAGE_LOTS_INFORMER';
  export const START_REBIDDING_SELECTION_PAGE_LOTS =
    'START_REBIDDING_SELECTION_PAGE_LOTS';

  export const START_REBIDDING_AUCTION_MODE = 'START_REBIDDING_AUCTION_MODE';
  export const START_REBIDDING_MINIMAL_AUCTION_STEP =
    'START_REBIDDING_MINIMAL_AUCTION_STEP';
  export const START_REBIDDING_WINNER_RESOLVE_TYPE =
    'START_REBIDDING_WINNER_RESOLVE_TYPE';
  export const START_REBIDDING_PRICE_OFFER_FILLINGS =
    'START_REBIDDING_PRICE_OFFER_FILLINGS';
  export const START_REBIDDING_REBIDDING_COMPARABLE_PRICE =
    'START_REBIDDING_REBIDDING_COMPARABLE_PRICE';
  export const START_REBIDDING_REBIDDING_PROLONGATION_MAX_AMOUNT =
    'START_REBIDDING_REBIDDING_PROLONGATION_MAX_AMOUNT';
  export const START_REBIDDING_REBIDDING_PROLONGATION_TIME =
    'START_REBIDDING_REBIDDING_PROLONGATION_TIME';
  export const START_REBIDDING_REBIDDING_PROPOSAL_ACCEPTANCE_END_DT =
    'START_REBIDDING_REBIDDING_PROPOSAL_ACCEPTANCE_END_DT';
  export const START_REBIDDING_REBIDDING_PROPOSAL_ACCEPTANCE_START_DT =
    'START_REBIDDING_REBIDDING_PROPOSAL_ACCEPTANCE_START_DT';
  export const START_REBIDDING_DISABLE_PRICE_BY_POSITION =
    'START_REBIDDING_DISABLE_PRICE_BY_POSITION';

  export const START_REBIDDING_REBIDDING_PAGE = 'START_REBIDDING_REBIDDING_PAGE';
  export const START_REBIDDING_REBIDDING_PAGE_FOOTER =
    'START_REBIDDING_REBIDDING_PAGE_FOOTER';
  export const START_REBIDDING_REBIDDING_PAGE_FOOTER_START_REBIDDING =
    'START_REBIDDING_REBIDDING_PAGE_FOOTER_START_REBIDDING';

  export const DECISION_COMMENT_AREA = 'DECISION_COMMENT_AREA';

  export const ACCREDITATION_REQUEST = 'ACCREDITATION_REQUEST';
  export const ACCREDITATION_REQUEST_DECISION = 'ACCREDITATION_REQUEST_DECISION';
  export const ACCREDITATION_REQUEST_DECISION_SUBMIT =
    'ACCREDITATION_REQUEST_DECISION_SUBMIT';
  export const ACCREDITATION_REQUEST_DECISION_CANCEL =
    'ACCREDITATION_REQUEST_DECISION_CANCEL';
  export const ACCREDITATION_REQUEST_DECISION_TRANSITION =
    'ACCREDITATION_REQUEST_DECISION_TRANSITION';
  export const ACCREDITATION_REQUEST_DECISION_INNER_COMMENT =
    'ACCREDITATION_REQUEST_DECISION_INNER_COMMENT';
  export const ACCREDITATION_REQUEST_DECISION_OUTER_COMMENT =
    'ACCREDITATION_REQUEST_DECISION_OUTER_COMMENT';

  export const ACCREDITATION_REQUEST_TABLE = 'ACCREDITATION_REQUEST_TABLE';
  export const ACCREDITATION_REQUEST_SUBMIT = 'ACCREDITATION_REQUEST_SUBMIT';
  export const ACCREDITATION_REQUEST_SAVE_AS_DRAFT =
    'ACCREDITATION_REQUEST_SAVE_AS_DRAFT';
  export const ACCREDITATION_REQUEST_SECTIONS = 'ACCREDITATION_REQUEST_SECTIONS';
  export const ACCREDITATION_REQUEST_SECTION = 'ACCREDITATION_REQUEST_SECTION';
  export const ACCREDITATION_REQUEST_ENTITY = 'ACCREDITATION_REQUEST_ENTITY';

  export const ACCREDITATION_REQUEST_ENTITY_TEXT = 'ACCREDITATION_REQUEST_ENTITY_TEXT';
  export const ACCREDITATION_REQUEST_ENTITY_EMAIL = 'ACCREDITATION_REQUEST_ENTITY_EMAIL';
  export const ACCREDITATION_REQUEST_ENTITY_PASSWORD =
    'ACCREDITATION_REQUEST_ENTITY_PASSWORD';
  export const ACCREDITATION_REQUEST_ENTITY_DATE = 'ACCREDITATION_REQUEST_ENTITY_DATE';

  export const ACCREDITATION_REQUEST_ENTITY_COLOR = 'ACCREDITATION_REQUEST_ENTITY_COLOR';
  export const ACCREDITATION_REQUEST_ENTITY_RANGE = 'ACCREDITATION_REQUEST_ENTITY_RANGE';
  export const ACCREDITATION_REQUEST_ENTITY_SELECT =
    'ACCREDITATION_REQUEST_ENTITY_SELECT';
  export const ACCREDITATION_REQUEST_ENTITY_TEXTAREA =
    'ACCREDITATION_REQUEST_ENTITY_TEXTAREA';
  export const ACCREDITATION_REQUEST_ENTITY_CHECKBOX =
    'ACCREDITATION_REQUEST_ENTITY_CHECKBOX';
  export const ACCREDITATION_REQUEST_ENTITY_FILE = 'ACCREDITATION_REQUEST_ENTITY_FILE';
  export const ACCREDITATION_REQUEST_ENTITY_SEARCH =
    'ACCREDITATION_REQUEST_ENTITY_SEARCH';
  export const ACCREDITATION_REQUEST_LAST_COMPETENCE_STATUS_ICON =
    'ACCREDITATION_REQUEST_LAST_COMPETENCE_STATUS_ICON';
}

export namespace NTenderClassnames {
  export const ACCREDITATION_REQUEST_TABLE = 'ACCREDITATION_REQUEST_TABLE';

  export const PROPOSAL_CARD = 'proposal-card';
  export const PROPOSAL_DROPDOWN_OVERLAY = 'PROPOSAL_DROPDOWN_OVERLAY';
  export const PROPOSAL_ESTIMATOR_DROPDOWN_OVERLAY =
    'PROPOSAL_ESTIMATOR_DROPDOWN_OVERLAY';
  export const MODAL_MAKE_DECISION = 'modal-make-decision';
  export const DECISION_LOT = 'decision-lot';
  export const CRITERIA_TABLE_ROW = 'CRITERIA_TABLE_ROW';
  export const CRITERIA_TABLE_CELL = 'CRITERIA_TABLE_CELL';
  export const DECISION_TABLE_CELL = 'DECISION_TABLE_CELL';
  export const DECISION_PROPOSAL_HEADER = 'DECISION_PROPOSAL_HEADER';
}

export namespace NProposalTestIds {
  export const PROPOSAL_COLLAPSE_CLARIFICATIONS_ARCHIVE =
    'PROPOSAL_COLLAPSE_CLARIFICATIONS_ARCHIVE';
  export const PROPOSAL_COLLAPSE_SUGGESTION = 'PROPOSAL_COLLAPSE_SUGGESTION';
  export const PROPOSAL_COLLAPSE_PARAMS = 'PROPOSAL_COLLAPSE_PARAMS';
  export const PROPOSAL_COLLAPSE_DOCS = 'PROPOSAL_COLLAPSE_DOCS';
  export const PROPOSAL_COLLAPSE_SUPPLY_CONDITIONS =
    'PROPOSAL_COLLAPSE_SUPPLY_CONDITIONS';
  export const PROPOSAL_COLLAPSE_PAYMENT_CONDITIONS =
    'PROPOSAL_COLLAPSE_PAYMENT_CONDITIONS';

  export const PROPOSAL_CARD = 'PROPOSAL_CARD';
  export const PROPOSAL_CARD_ID = 'PROPOSAL_CARD_ID';
  export const PROPOSAL_CARD_UNREAD_MARKER = 'PROPOSAL_CARD_UNREAD_MARKER';

  export const PROPOSAL_REVOKE_BUTTON = 'PROPOSAL_REVOKE_BUTTON';

  export const PROPOSAL_OPEN_CARD_MENU = 'PROPOSAL_OPEN_CARD_MENU';

  export const PROPOSAL_CARD_MENU_DOWNLOAD = 'PROPOSAL_CARD_MENU_DOWNLOAD';
  export const PROPOSAL_CARD_MENU_OPEN_COMPANY = 'PROPOSAL_CARD_MENU_OPEN_COMPANY';
  export const PROPOSAL_CARD_MENU_OPEN_CHAT = 'PROPOSAL_CARD_MENU_OPEN_CHAT';
  export const PROPOSAL_CARD_MENU_CLARIFY = 'PROPOSAL_CARD_MENU_CLARIFY';
  export const PROPOSAL_CARD_MENU_REJECT = 'PROPOSAL_CARD_MENU_REJECT';
  export const PROPOSAL_CARD_MENU_REVOKE = 'PROPOSAL_CARD_MENU_REVOKE';
  export const PROPOSAL_CARD_MENU_REMOVE_DRAFT = 'PROPOSAL_CARD_MENU_REMOVE_DRAFT';
  export const PROPOSAL_CARD_MENU_EDIT = 'PROPOSAL_CARD_MENU_EDIT';
  export const PROPOSAL_CARD_MENU_RETURN_TO_REVIEW =
    'PROPOSAL_CARD_MENU_RETURN_TO_REVIEW';

  export const PROPOSAL_STATUS = 'PROPOSAL_STATUS';
  export const COMMENT_CLARIFICATION_MESSAGE = 'COMMENT_CLARIFICATION_MESSAGE';

  export const ESTIMATOR_RECOMMEND_BTN = 'ESTIMATOR_RECOMMEND_BTN';
  export const ESTIMATOR_NOT_RECOMMEND_BTN = 'ESTIMATOR_NOT_RECOMMEND_BTN';

  export const ADD_PROPOSAL_SUBMIT_BUTTON = 'ADD_PROPOSAL_SUBMIT_BUTTON';

  export const PROPOSAL_MODAL = 'PROPOSAL_MODAL';
  export const PROPOSAL_SUBMIT_BUTTON = 'PROPOSAL_SUBMIT_BUTTON';
  export const PROPOSAL_SAVE_AS_DRAFT_BUTTON = 'PROPOSAL_SAVE_AS_DRAFT_BUTTON';
  export const PROPOSAL_AGREE_WITH_INFO_CHECKBOX = 'PROPOSAL_AGREE_WITH_INFO_CHECKBOX';
  export const PROPOSAL_DOWNLOAD_TEMPLATE_BUTTON = 'PROPOSAL_DOWNLOAD_TEMPLATE_BUTTON';
  export const PROPOSAL_UPLOAD_TEMPLATE_BUTTON = 'PROPOSAL_UPLOAD_TEMPLATE_BUTTON';
  export const PROPOSAL_VAT_SELECT = 'PROPOSAL_VAT_SELECT';
  export const PROPOSAL_VAT_CHECKBOX = 'PROPOSAL_VAT_CHECKBOX';
  export const PROPOSAL_EDIT_PBE_TABLE = 'PROPOSAL_EDIT_PBE_TABLE';
  export const DELIVERY_ORDER = 'DELIVERY_ORDER';
  export const PROPOSAL_HASH_MODAL = 'PROPOSAL_HASH_MODAL';
  export const PROPOSAL_HASH_MODAL_UPLOAD_PROPOSAL =
    'PROPOSAL_HASH_MODAL_UPLOAD_PROPOSAL';

  export const PROPOSAL_ARCHIEVE_ANSWER = 'PROPOSAL_ARCHIEVE_ANSWER';

  // #region parameters WAS
  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_REQUIREMENTS_FOR_AMOUNT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_REQUIREMENTS_FOR_AMOUNT';
  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_REQUIREMENTS_FOR_AMOUNT_INPUT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_REQUIREMENTS_FOR_AMOUNT_INPUT';

  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_PREPAYMENT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_PREPAYMENT';
  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_PREPAYMENT_INPUT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_PREPAYMENT_INPUT';

  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WARRANTY_PERIOD =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WARRANTY_PERIOD';
  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WARRANTY_PERIOD_INPUT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WARRANTY_PERIOD_INPUT';

  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WORKS_PERIOD =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_WORKS_PERIOD';
  export const PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_TOTAL_COMPLETION_TIME_INPUT =
    'PROPOSAL_PARAMETER_WAS_IS_AGREE_TO_TOTAL_COMPLETION_TIME_INPUT';
  // #endregion

  // #region parameters CAM
  export const PARAMENER_CAM_SUPPLIER_STATUS = 'PARAMENER_CAM_SUPPLIER_STATUS';

  export const PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_WARRANTY_PERIOD =
    'PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_WARRANTY_PERIOD';
  export const PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_WARRANTY_PERIOD_INPUT =
    'PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_WARRANTY_PERIOD_INPUT';

  export const PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_TIME =
    'PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_TIME';
  export const PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_TIME_INPUT =
    'PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_TIME_INPUT';

  export const PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_PERIOD =
    'PROPOSAL_PARAMETER_CAM_IS_AGREE_TO_DELIVERY_PERIOD';

  export const PROPOSAL_PARAMETER_CAM_MANUFACTURER =
    'PROPOSAL_PARAMETER_CAM_MANUFACTURER';
  export const PROPOSAL_PARAMETER_CAM_MANUFACTURER_COUNTRY =
    'PROPOSAL_PARAMETER_CAM_MANUFACTURER_COUNTRY';
  export const PROPOSAL_PARAMETER_CAM_MANUFACTURER_BRAND =
    'PROPOSAL_PARAMETER_CAM_MANUFACTURER_BRAND';
  export const PROPOSAL_PARAMETER_CAM_CONFORMITY_TO_REQUIREMENTS =
    'PROPOSAL_PARAMETER_CAM_CONFORMITY_TO_REQUIREMENTS';
  export const PROPOSAL_CAM_TRANSPORT_COSTS = 'PROPOSAL_CAM_TRANSPORT_COSTS';
  // #endregion

  export const PROPOSAL_VIEW_WARRANTY_PERIOD_CHECKBOX =
    'PROPOSAL_VIEW_WARRANTY_PERIOD_CHECKBOX';
  export const PROPOSAL_VIEW_WARRANTY_PERIOD_SECURITY_CHECKBOX =
    'PROPOSAL_VIEW_WARRANTY_PERIOD_SECURITY_CHECKBOX';
  export const PROPOSAL_VIEW_DELIVERY_TIME_CHECKBOX =
    'PROPOSAL_VIEW_DELIVERY_TIME_CHECKBOX';
  export const PROPOSAL_VIEW_DELIVERY_PERIOD_CHECKBOX =
    'PROPOSAL_VIEW_DELIVERY_PERIOD_CHECKBOX';
  export const PROPOSAL_VIEW_PROJECT_AGREE_CHECKBOX =
    'PROPOSAL_VIEW_PROJECT_AGREE_CHECKBOX';
  export const PROPOSAL_VIEW_AGREE_PREPAYMENT_CHECKBOX =
    'PROPOSAL_VIEW_AGREE_PREPAYMENT_CHECKBOX';
  export const PROPOSAL_VIEW_WORKS_PERIOD_CHECKBOX =
    'PROPOSAL_VIEW_WORKS_PERIOD_CHECKBOX';

  export const PROPOSAL_VIEW_GENERAL_AGREE_CHECKBOX =
    'PROPOSAL_VIEW_GENERAL_AGREE_CHECKBOX';
  export const PROPOSAL_VIEW_NOT_VAT_PAYER_CHECKBOX =
    'PROPOSAL_VIEW_NOT_VAT_PAYER_CHECKBOX';

  export const PROPOSAL_PAYMENT_POSTPAYMENT_OWN_CONDITIONS =
    'PROPOSAL_PAYMENT_POSTPAYMENT_OWN_CONDITIONS';
  export const PROPOSAL_PAYMENT_OWN_DEFERRED_PAYMENT_TERMS =
    'PROPOSAL_PAYMENT_OWN_DEFERRED_PAYMENT_TERMS';

  export const PROPOSAL_DOCUMENTS_PROPOSAL = 'PROPOSAL_DOCUMENTS_PROPOSAL';
  export const PROPOSAL_DOCUMENTS_ORDER = 'PROPOSAL_DOCUMENTS_ORDER';

  export const PROPOSAL_ADDITIONAL_CRITERIA = 'PROPOSAL_ADDITIONAL_CRITERIA';
  export const PROPOSAL_ADDITIONAL_CRITERIA_TYPE_CHECKBOX =
    'PROPOSAL_ADDITIONAL_CRITERIA_TYPE_CHECKBOX';
  export const PROPOSAL_ADDITIONAL_CRITERIA_TYPE_FILE =
    'PROPOSAL_ADDITIONAL_CRITERIA_TYPE_FILE';
  export const PROPOSAL_ADDITIONAL_CRITERIA_TYPE_NUMBER =
    'PROPOSAL_ADDITIONAL_CRITERIA_TYPE_NUMBER';
  export const PROPOSAL_ADDITIONAL_CRITERIA_TYPE_DROPDOWN =
    'PROPOSAL_ADDITIONAL_CRITERIA_TYPE_DROPDOWN';
  export const PROPOSAL_ADDITIONAL_CRITERIA_TYPE_TEXT =
    'PROPOSAL_ADDITIONAL_CRITERIA_TYPE_TEXT';

  export const PROPOSAL_CRITERIA_RATING = 'PROPOSAL_CRITERIA_RATING';
  export const PROPOSAL_CRITERIA_RATING_LINK = 'PROPOSAL_CRITERIA_RATING_LINK';
  export const ESTIMATOR_CHECK_BUTTON = 'ESTIMATOR_CHECK_BUTTON';
}

export namespace NProposalRating {
  export const PROPOSAL_RATING = 'PROPOSAL_RATING';
  export const RATING_INFO_LINK = 'RATING_INFO_LINK';
  export const INFO_TITLE = 'INFO_TITLE';
  export const INFO_RATING = 'INFO_RATING';
  export const INFO_REPORT_BUTTON = 'INFO_REPORT_BUTTON';
  export const INFO_PREVIOUS_PROPOSAL = 'INFO_PREVIOUS_PROPOSAL';
  export const INFO_NEXT_PROPOSAL = 'INFO_NEXT_PROPOSAL';
  export const INFO_SEND_REPORT_MODAL = 'INFO_SEND_REPORT_MODAL';
  export const INFO_RATING_TABLE = 'INFO_RATING_TABLE';
  export const INFO_RATING_TABLE_ARROW_UP = 'INFO_RATING_TABLE_ARROW_UP';
  export const INFO_RATING_TABLE_ARROW_DOWN = 'INFO_RATING_TABLE_ARROW_DOWN';
  export const INFO_RATING_TABLE_ARROW_UP_AND_DOWN =
    'INFO_RATING_TABLE_ARROW_UP_AND_DOWN';
}

export namespace NTenderGreenTable {
  export const DECISION_TABLE = 'DECISION_TABLE';
  export const SAVE_DECISION = 'SAVE_DECISION';
  export const PROPOSAL_HEADER = 'PROPOSAL_HEADER';
  export const DECISION_TYPE = 'DECISION_TYPE';
}

export namespace NCompanyTestIds {
  export const ACCREDITATION_HISTORY = 'ACCREDITATION_HISTORY';
  export const ACCREDITATION_STATUS_TITLE = 'ACCREDITATION_STATUS_TITLE';
  export const ACCREDITATION_STATUS_DATE = 'ACCREDITATION_STATUS_DATE';
  export const ACCREDITATION_TABLE = 'ACCREDITATION_TABLE';
  export const ACCREDITATION_HISTORY_TABLE = 'ACCREDITATION_HISTORY_TABLE';
  export const ACCREDITATION_HISTORY_TABLE_TITLE = 'ACCREDITATION_HISTORY_TABLE_TITLE';
  export const ACCREDITATION_EXECUTOR_MODAL_EMPLOYEE_INPUT =
    'ACCREDITATION_EXECUTOR_MODAL_EMPLOYEE_INPUT';
  export const ACCREDITATION_SOLUTION_MODAL_DECISION_INPUT =
    'ACCREDITATION_SOLUTION_MODAL_DECISION_INPUT';
  export const ACCREDITATION_SOLUTION_MODAL_DECISION_FORM_FIELD =
    'ACCREDITATION_SOLUTION_MODAL_DECISION_FORM_FIELD';
  export const ACCREDITATION_SOLUTION_MODAL_SPECIALIZATIONS_BUTTON =
    'ACCREDITATION_SOLUTION_MODAL_SPECIALIZATIONS_BUTTON';
  export const ACCREDITATION_SOLUTION_MODAL_APPROVED_SPECS_FORM =
    'ACCREDITATION_SOLUTION_MODAL_APPROVED_SPECS_FORM';
  export const EDIT_COMPANY_BUTTON = 'EDIT_COMPANY_BUTTON';
  export const SEND_TO_ACCREDITATION_BUTTON = 'SEND_TO_ACCREDITATION_BUTTON';

  export const MAKE_DECISION_BUTTON = 'MAKE_DECISION_BUTTON';
  export const CHANGE_DECISION_BUTTON = 'CHANGE_DECISION_BUTTON';
  export const ACTIONS_BUTTON = 'ACTIONS_BUTTON';
  export const REQUEST_ADDITIONAL_DATA = 'REQUEST_ADDITIONAL_DATA';
  export const ASSIGN_TO_SELF_BUTTON = 'ASSIGN_TO_SELF_BUTTON';
  export const TAKE_OFF_BUTTON = 'TAKE_OFF_BUTTON';
  export const LOGBOOK_DOCUMENTS_LIST = 'LOGBOOK_DOCUMENTS_LIST';

  export const PARTICIPATION_HISTORY_TABLE = 'PARTICIPATION_HISTORY_TABLE';
  export const PARTICIPATION_HISTORY_INFO_PARTICIPATED =
    'PARTICIPATION_HISTORY_INFO_PARTICIPATED';
  export const PARTICIPATION_HISTORY_INFO_WON = 'PARTICIPATION_HISTORY_INFO_WON';
  export const PARTICIPATION_HISTORY_INFO_RESERVED =
    'PARTICIPATION_HISTORY_INFO_RESERVED';
  export const PARTICIPATION_HISTORY_INFO_TIME_ON = 'PARTICIPATION_HISTORY_INFO_TIME_ON';
}

export namespace NReviewTestIds {
  export const REVIEW_SOLUTION_MODAL_APPROVER_INPUT =
    'REVIEW_SOLUTION_MODAL_APPROVER_INPUT';
}

// UI компоненты
export namespace NComponentsUI {
  export const CHAT_BUTTON = 'CHAT_BUTTON';
  export const SWITCH = 'SWITCH';
  export const TABLE_ACTIONS_CONTAINER = 'TABLE_ACTIONS_CONTAINER';
  export const TABLE_ACTIONS_EDIT_BUTTON_ICON = 'TABLE_EDIT_BUTTON_ICON';
  export const TABLE_ACTIONS_REMOVE_BUTTON_ICON = 'TABLE_REMOVE_BUTTON_ICON';
  export const RANGE_PICKER_WRAPPER = 'RANGE_PICKER_WRAPPER';

  export const PAGE_TITLE = 'PAGE_TITLE';
}

export namespace NReviewCard {
  export const COMPANY_NAME_LINK = 'COMPANY_NAME_LINK';
}

export namespace NPbeElements {
  export const DELETE_SELECTED_BUTTON = 'DELETE_SELECTED_BUTTON';
  export const ADD_BUTTON = 'ADD_BUTTON';
  export const ACTIONS_WITH_SELECTED = 'ACTIONS_WITH_SELECTED';
  export const ACTIONS_WITH_SELECTED_COUNT = 'ACTIONS_WITH_SELECTED_COUNT';
}

export namespace NModalObjects {
  export const SELECTED_TAG = 'SELECTED_TAG';
}

export namespace NPreviewModal {
  export const IMAGE_VIEW = 'IMAGE_VIEW';
  export const PDF_VIEW = 'PDF_VIEW';
  export const NO_PREVIEW_VIEW = 'NO_PREVIEW_VIEW';
  export const ROTATE_LEFT_BUTTON = 'ROTATE_LEFT_BUTTON';
  export const ROTATE_RIGHT_BUTTON = 'ROTATE_RIGHT_BUTTON';
  export const ZOOM_OUT_BUTTON = 'ZOOM_OUT_BUTTON';
  export const ZOOM_IN_BUTTON = 'ZOOM_IN_BUTTON';
  export const DOWNLOAD_BUTTON = 'DOWNLOAD_BUTTON';
  export const CLOSE_BUTTON = 'CLOSE_BUTTON';
  export const PREVIOUS_ARROW_CONTROL = 'PREVIOUS_ARROW_CONTROL';
  export const NEXT_ARROW_CONTROL = 'NEXT_ARROW_CONTROL';
}

export namespace NContractsTestIds {
  export const CONTRACTS_LIST_TABLE = 'CONTRACTS_LIST_TABLE';
  export const CONTRACTS_PAGINATION = 'CONTRACTS_PAGINATION';
  export const CONTRACTS_FILTER = 'CONTRACTS_FILTER';
  export const CONTRACT_TITLE_LINK = 'CONTRACT_TITLE_LINK';
  export const CONTRACT_RSS_TABLE = 'CONTRACT_RSS_TABLE';
}

export namespace NLotProposalsAnalysisTable {
  export const ROOT = 'LOT_PROPOSALS_ANALYSIS_TABLE_ROOT';
  export const PROPOSAL_INFO_CONTAINER = 'PROPOSAL_INFO_CONTAINER';
  export const LOT_TABLE = 'LOT_TABLE';
  export const PROPOSAL_TABLE = 'PROPOSAL_TABLE';
  export const OTHER_CONDITIONS = 'OTHER_CONDITIONS';

  export const PROPOSAL_INFO_PRICE = 'PROPOSAL_INFO_PRICE';

  export const PROPOSAL_INFO_SUM = 'PROPOSAL_INFO_SUM';

  export const PROPOSAL_INFO_IF_FULL = 'PROPOSAL_INFO_IF_FULL';

  export const PROPOSAL_INFO_POSITION_RATING = 'PROPOSAL_INFO_POSITION_RATING';
  export const PROPOSAL_INFO_ANALOG = 'PROPOSAL_INFO_ANALOG';

  export const LOT_INFO_CONTAINER = 'LOT_INFO_CONTAINER';

  export const LOT_INFO_PRICE = 'LOT_INFO_PRICE';

  export const LOT_INFO_SUM = 'LOT_INFO_SUM';

  export const LOT_ROW_NAME = 'LOT_ROW_NAME';
  export const LOT_ROW_SPECS = 'LOT_ROW_SPECS';

  export const PROPOSAL_HEAD_CELL_CONTAINER = 'PROPOSAL_HEAD_CELL_CONTAINER';

  export const PROPOSAL_HEAD_CELL_COMPANY_LINK = 'PROPOSAL_HEAD_CELL_COMPANY_LINK';
  export const PROPOSAL_HEAD_CELL_COMPANY_TIN = 'PROPOSAL_HEAD_CELL_COMPANY_TIN';
  export const PROPOSAL_HEAD_CELL_FILLED_POSITIONS_COUNT =
    'PROPOSAL_HEAD_CELL_FILLED_POSITIONS_COUNT';

  export const PROPOSAL_HEAD_CELL_SUM = 'PROPOSAL_HEAD_CELL_SUM';

  export const PROPOSAL_HEAD_CELL_VAT = 'PROPOSAL_HEAD_CELL_VAT';

  export const OTHER_CONDITIONS_LOT_TABLE = 'OTHER_CONDITIONS_LOT_TABLE';
  export const OTHER_CONDITIONS_LOT_ROW_NAME = 'OTHER_CONDITIONS_LOT_ROW_NAME';
  export const OTHER_CONDITIONS_PROPOSAL_TABLE = 'OTHER_CONDITIONS_PROPOSAL_TABLE';
}

export namespace NApproversAddModal {
  export const APPROVERS_ADD_MODAL = 'APPROVERS_ADD_MODAL';
  export const APPROVERS_ADD_TABLE = 'APPROVERS_ADD_TABLE';
  export const IS_BLOCKER = 'IS_BLOCKER';
  export const RADIO_BUTTON = 'RADIO_BUTTON';
  export const BLOCKING_STAGE = 'BLOCKING_STAGE';
  export const IS_SHOW_CONTACT = 'IS_SHOW_CONTACT';
}

export namespace NApproversTestIds {
  export const APPROVERS_ADD_MODAL = 'APPROVERS_ADD_MODAL';
  export const APPROVERS_ADD_TABLE = 'APPROVERS_ADD_TABLE';
  export const ADD_APPROVERS_BUTTON = 'ADD_APPROVERS_BUTTON';
  export const APPROVER_CARD = 'APPROVER_CARD';
  export const APPROVER_CARD_STATUS = 'APPROVER_CARD_STATUS';
  export const APPROVER_CARD_DECISION = 'APPROVER_CARD_DECISION';
  export const APPROVER_CARD_NOTIFICATION_BTN = 'APPROVER_CARD_NOTIFICATION_BTN';
  export const APPROVER_CARD_DELETE_BTN = 'APPROVER_CARD_DELETE_BTN';
  export const APPROVER_CARD_SHOW_CONTACT = 'APPROVER_CARD_SHOW_CONTACT';
  export const APPROVER_CARD_BLOCKING_STATUS = 'APPROVER_CARD_BLOCKING_STATUS';
  export const APPROVER_CARD_BLOCKING_STAGE = 'APPROVER_CARD_BLOCKING_STAGE';
  export const APPROVER_CARD_PHONE = 'APPROVER_CARD_PHONE';
  export const APPROVER_CARD_POSITION = 'APPROVER_CARD_POSITION';
  export const APPROVER_CARD_NAME = 'APPROVER_CARD_NAME';
}

export namespace NHeaderTestIds {
  export const HEADER = 'HEADER';
}
