import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface IScrollYPosition {
  scrollY: number;
}

const ScrollYPosition = React.createContext<IScrollYPosition>({} as IScrollYPosition);

export const ScrollYPositionProvider: React.FC = ({ children }) => {
  const [scrollY, setScrollY] = useState(0);
  const layoutContent = document.querySelector('.ant-layout-header + div');
  const handleScroll = useCallback(event => {
    const currentScroll = event?.target?.scrollTop || 0;

    setScrollY(currentScroll);
  }, []);

  useEffect(() => {
    if (layoutContent) {
      layoutContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (layoutContent) {
        layoutContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, layoutContent]);

  const value = useMemo(
    () => ({
      scrollY,
    }),
    [scrollY],
  );

  return <ScrollYPosition.Provider value={value}>{children}</ScrollYPosition.Provider>;
};

export const useScrollYPosition = () => useContext(ScrollYPosition);
