import React, { useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Centrifuge, State } from 'centrifuge';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from 'context/Auth';
import { getWebsocketsUrl } from 'api/routes';
import { IWebsockets, nonErrorsCodes } from './types';

const Websockets = React.createContext<IWebsockets>({} as IWebsockets);

export const WebsocketsProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient();
  const { token, provider } = useAuth();

  const ws = useMemo(
    () =>
      new Centrifuge(getWebsocketsUrl(), {
        token,
        getToken: provider.getPromisifiedToken.bind(provider),
      }),
    [provider, token],
  );

  useEffect(() => {
    if (ws) {
      if (ws.state === State.Disconnected) {
        ws.connect();
      }

      ws.on('publication', ({ data, channel }) => {
        const { messageData, messageType } = data;
        queryClient.setQueryData([messageType, messageData.id], messageData);
        queryClient.setQueryData([channel], messageData);
      });

      ws.on('error', event => {
        if (nonErrorsCodes.includes(event.error.code)) return;

        withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra(
            'message',
            `Captured websockets error code ${event.error.code} with message ${event.error.message}`,
          );
          captureException(event);
        });
      });

      return () => {
        ws.disconnect();
      };
    }
  }, [queryClient, ws]);

  const value = useMemo<IWebsockets>(() => ({ ws }), [ws]);

  return <Websockets.Provider value={value}>{children}</Websockets.Provider>;
};

export const useWebsockets = () => useContext(Websockets);
