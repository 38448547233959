import { getRealm } from 'samolet-oauth2';
import { instance } from 'api/request';
import { IAuthProvider } from './type';
import { KeycloakProvider, SamoletProvider } from './providers';
import { Envs } from './common/enums';

export const authType = process.env.ENVIRON;

export class CurrentProvider {
  public getCurrentProvider(): IAuthProvider {
    // Здесь не получится получить фича тоггл через стор,
    // т.к. возникают циклические зависимости
    const waffle = window?.QUERIES_CACHE_KEY?.waffle || {};
    const denyAnonymous = Boolean(waffle?.denyAnonymous?.isEnabled);

    switch (authType) {
      case Envs.SAAS:
        return new KeycloakProvider({
          clientId: process.env.KEYCLOAK_OAUTH2_CLIENT_ID ?? '',
          allowAnonymous: !denyAnonymous,
          realm: getRealm(),
          url: process.env.KEYCLOAK_URL,
        });
      case Envs.SAMOLET:
      default:
        return new SamoletProvider({
          clientId: process.env.SOCIAL_AUTH_SAMOLET_KEY ?? '',
          allowAnonymous: true,
        });
    }
  }
}

export const currentProvider = new CurrentProvider().getCurrentProvider();
currentProvider.setAuthInterceptor(instance);
