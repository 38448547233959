import 'reflect-metadata';
import 'utils/wdyr';
import 'core-js/actual/object/has-own';
import React from 'react';
import ReactDOM from 'react-dom';
import initSentry from 'utils/sentry';
import { replaceFavicon } from 'utils/favicon';
import { getStandFromEnv } from 'utils/stand';
import 'moment/locale/ru';
import App from 'App';

import 'style.css';

const init = () => {
  const stand = getStandFromEnv();

  initSentry(stand);
  replaceFavicon(stand);
  ReactDOM.render(<App />, document.getElementById('root'));
};

init();
