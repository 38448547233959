import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeaderProps } from '@10d/tend-ui-header';
import { Notifications } from '@10d/tend-ui-notifications';
import { TAppVersion } from 'api/types';
import {
  AsideMenuItemsKeys,
  asideMenuItems,
  asideMenuItemsRoutes,
} from 'features/AsideMenu';
import { asideMenuItemsValues } from 'features/AsideMenu/types';
import { isEmpty } from 'helpers/object';
import { useProfile, useProfileStorage } from 'hooks/profile';
import { authType, currentProvider } from 'context/Auth/helpers';
import useAnalyticsLogger, {
  EAnalyticsLoggerEventNames,
  caseFactory,
} from 'controllers/loggers/AnalitycsLoggerController/hooks';
import { useCheckVersion } from 'features/VersionChecker/useCheckVersion';
import { selectFeature } from 'store/common/toggle';
import { Icon } from 'components/Icon';
import { getStandFromEnv } from 'utils/stand';
import { Envs } from 'context/Auth/common/enums';

const asStageStands = [TAppVersion.DEV, TAppVersion.E2E];
const routes = Object.values(asideMenuItemsRoutes);
const keys = Object.values(AsideMenuItemsKeys);

export const useHeader = (): HeaderProps => {
  const { profile, isAnonymous } = useProfileStorage();
  const { permissions } = useProfile();
  const { appVersion } = useCheckVersion();
  const logger = useAnalyticsLogger();
  const envStand = getStandFromEnv();

  const { isEnabled: isContractHidden } = useSelector(selectFeature('hideContract'));
  const { pathname } = location;

  const [selectedAsideItem, setSelectedAsideItem] = useState<AsideMenuItemsKeys>(
    AsideMenuItemsKeys.COMPANY,
  );

  useEffect(() => {
    const key = routes.findIndex(route => pathname.startsWith(route));
    setSelectedAsideItem(Object.keys(asideMenuItemsRoutes)[key] as AsideMenuItemsKeys);
  }, [pathname]);

  const handleOnClick = useCallback(
    value => {
      const key = keys.includes(value) ? value : value[0];

      logger.log(
        caseFactory({ name: EAnalyticsLoggerEventNames.LeftPanelClick }).context(
          asideMenuItemsValues[key as keyof typeof AsideMenuItemsKeys],
        ),
      );
      setSelectedAsideItem(key);
    },
    [logger],
  );

  const menuItems = useMemo(
    () =>
      asideMenuItems({
        employee: Boolean(profile?.isEmployee),
        notEmployee: Boolean(!isEmpty(profile) && !profile.isEmployee),
        permissions,
        isAnonymous,
        hideContract: isContractHidden,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick: handleOnClick,
      }),
    [profile, permissions, isAnonymous, isContractHidden, handleOnClick],
  );

  const navigation: HeaderProps['navigation'] = useMemo(() => {
    return {
      items: menuItems,
      onSelect: handleOnClick,
      selectedKeys: [selectedAsideItem],
    };
  }, [handleOnClick, menuItems, selectedAsideItem]);

  const stand = useMemo(
    () =>
      (asStageStands.includes(envStand)
        ? TAppVersion.STAGE
        : envStand
      ).toLowerCase() as unknown as HeaderProps['stand'],
    [envStand],
  );

  return useMemo(
    () => ({
      app: 's.tender',
      user: isAnonymous ? undefined : { ...profile },
      authenticated: !isAnonymous,
      stand,
      navigation,
      profile: isAnonymous
        ? undefined
        : {
            items: profile?.isSuperuser
              ? [
                  { key: 'version', label: appVersion?.version || '-', disabled: true },
                  { key: 'divider', type: 'divider' },
                  {
                    key: 'admin',
                    label: 'Админка',
                    icon: <Icon type='AccountCircle' color='gray400' />,
                    onClick: () => {
                      window.open('/admin/', '_blank');
                    },
                  },
                ]
              : [],
          },
      authorization: {
        onSignin: () => currentProvider.login(),
        onSignup: () => currentProvider.login(),
      },
      onLogout: () => currentProvider.logout(),
      slots:
        isAnonymous || authType === Envs.SAAS
          ? undefined
          : {
              notifications: <Notifications />,
            },
    }),
    [appVersion?.version, isAnonymous, navigation, profile, stand],
  );
};
