import messageAnt, { ArgsProps, ConfigOnClose, MessageType } from 'antd/lib/message';
import React from 'react';
import _ from 'lodash';
import { Icon } from 'components/Icon';
import { ConfigDuration, JointContent } from './types.old';

const isArgsProps = (content: JointContent): content is ArgsProps => {
  return _.isObject(content) && !!(content as ArgsProps).content;
};

const message = _.cloneDeep(messageAnt);

(['success', 'info', 'error', 'warning', 'loading'] as const).forEach(type => {
  const messageTypeFn = (
    content: JointContent,
    duration: ConfigDuration = 5,
    onClose?: ConfigOnClose,
  ): MessageType => {
    const getIcon = () => {
      switch (type) {
        case 'success':
          return <Icon type='DoneCircle' />;
        case 'error':
          return <Icon type='Close' />;
        case 'loading':
          return <Icon type='Refresh' />;
        default:
          return <Icon type='Info' />;
      }
    };

    let config: ArgsProps;

    if (isArgsProps(content)) {
      config = {
        icon: getIcon(),
        duration: duration as ArgsProps['duration'],
        ...content,
      };
    } else {
      config = {
        content,
        duration: duration as ArgsProps['duration'],
        onClose,
        type,
        icon: getIcon(),
      };
    }

    return messageAnt[type](config);
  };

  message[type] = messageTypeFn;
});

export default message;
