import { TAppVersion } from 'api/types';
export const getStandFromEnv = (): TAppVersion => {
  const envStand = process?.env?.STAND || TAppVersion.DEV;

  switch (envStand) {
    case TAppVersion.PROD:
      return TAppVersion.PROD;
    case TAppVersion.STAGE:
      return TAppVersion.STAGE;
    default:
      return TAppVersion.DEV;
  }
};
