import { TIME_MINUTES_INVERVAL } from '@webcommon/constants';
import moment, { Moment as IMoment } from 'moment';

export const getMoment = moment;
export type Moment = IMoment;
export type DateType = string | Moment | null;

export const DATE_FORMAT = 'DD.MM.YYYY';

export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `DD.MM.YYYY ${TIME_FORMAT}`;
export const DATE_AT_TIME_FORMAT = `DD.MM.YYYY в ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_SHORT = `DD.MM.YY ${TIME_FORMAT}`;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const ANT_FORMAT = 'YYYY/MM/DD';

const normalizedMomentDate = (date: Moment) => date.set({ second: 0, millisecond: 0 });

export const getFormatDate = (date?: string, format?: string) => {
  return date ? moment(date, format).format(DATE_FORMAT) : '';
};

export const parseToPickerFormat = (date: string) =>
  normalizedMomentDate(moment(date, ANT_FORMAT));

export const getFormatDateTime = (
  date?: string | Moment,
  short?: boolean,
  format: string = DATE_TIME_FORMAT,
) => {
  return date ? moment(date).format(short ? DATE_TIME_FORMAT_SHORT : format) : '';
};

export const getFromDateTimeString = (date: string) => {
  return moment(date, DATE_TIME_FORMAT);
};

export const getDiffDates = (firstDate: string, secondDate: string) => {
  return +moment(firstDate) - +moment(secondDate);
};

export const parseToServerDate = (date?: string | Moment) => {
  return date ? moment(date, DATE_FORMAT).format(SERVER_DATE_FORMAT) : null;
};

export const parseToServerTimeDate = (date?: string | Moment) => {
  return date ? `${moment.utc(date).format()}` : '';
};

export const toMomentDate = (date?: DateType) => {
  if (!date) return null;

  if (moment.isMoment(date)) return normalizedMomentDate(date);

  return normalizedMomentDate(moment(date));
};

export const toMomentTime = (date?: string) => {
  return date ? moment(date, TIME_FORMAT) : undefined;
};

export const disabledDate = (
  date: Moment,
  minDate?: Moment | string | null,
  maxDate?: Moment | string | null,
) => {
  return Boolean(
    !date || (minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate)),
  );
};

export const dateExpired = (date: string | Moment) => moment(date).isBefore();

type TDate = Moment | null | undefined;
type TReturn = {
  disabledHours?: (() => number[]) | undefined;
  disabledMinutes?: ((hour: number) => number[]) | undefined;
  disabledSeconds?: ((hour: number, minute: number) => number[]) | undefined;
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};

export const timeRange =
  ({
    from,
    to,
    interval = TIME_MINUTES_INVERVAL,
  }: {
    from?: TDate | string;
    to?: TDate | string;
    interval?: number;
  }) =>
  (current?: TDate): TReturn => {
    const toMoment = to ? getMoment(to) : undefined;
    const fromMoment = from ? getMoment(from) : undefined;

    return {
      disabledHours: () => {
        if (current?.day() === undefined) return range(0, 24);

        const disabledHours: ReturnType<typeof range> = [];

        if (fromMoment && current.isSame(fromMoment, 'days')) {
          // Если все минуты заблочены, то блокируем этот час
          let additional = 0;
          if (60 - interval > fromMoment.minutes()) {
            additional = -1;
          }
          disabledHours.push(...range(0, fromMoment.hours() + additional));
        }
        if (toMoment && current.isSame(toMoment, 'days')) {
          let additional = 0;
          if (toMoment.minutes() > interval) {
            additional = 1;
          }
          disabledHours.push(...range(toMoment.hours() + additional, 24));
        }

        return disabledHours;
      },
      disabledMinutes: (hour: number) => {
        if (current?.day() === undefined || hour < -1) return range(0, 60);

        const disabledMinutes: ReturnType<typeof range> = [];

        if (fromMoment && current.isSame(fromMoment, 'hours')) {
          disabledMinutes.push(...range(0, fromMoment.minutes()));
        }
        if (toMoment && current.isSame(toMoment, 'hours')) {
          disabledMinutes.push(...range(toMoment.minutes(), 60));
        }

        return disabledMinutes;
      },
      disabledSeconds: () => [] as number[],
    };
  };
