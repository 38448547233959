export const experimentColors = {
  experimentGray1: '#EAEDF5D6',
  experimentGray2: '#D7DCE9D6',
};

export const paletteColors = {
  blue1: '#E5F2FF',
  blue2: '#CCE5FE',
  blue3: '#99CAFE',
  blue4: '#66B0FD',
  blue5: '#3395FC',
  blue6: '#007BFB',
  blue7: '#0062C9',
  blue8: '#004A97',
  blue9: '#003164',
  blue10: '#002766',
  blue11: '#f7f9fe',
  blue12: '#5f6a86',
  blue13: '#1890ff',
  blue14: '#f2f3f4',
  blue15: '#F6FAFF',
  blue16: '#F2F8FF',

  gray1: '#FFFFFF',
  gray2: '#F7F9FE',
  gray3: '#EAEDF5',
  gray4: '#D7DCE9',
  gray5: '#BDC5DB',
  gray6: '#A5A7AF',
  gray7: '#6C717C',
  gray8: '#343A4A',
  gray9: '#343A4A',
  gray10: '#000000',
  gray11: '#a7b2c1',
  gray12: '#8E919A',

  grayBlue1: '#ffffff',
  grayBlue2: '#F9FAFA',
  grayBlue3: '#F4F4F5',
  grayBlue4: '#DDDEE1',
  grayBlue5: '#D2D3D7',
  grayBlue6: '#AEB8D0',
  grayBlue7: '#8F9CBC',
  grayBlue8: '#4a5568',
  grayBlue9: '#2D3748',
  grayBlue10: '#1a202c',

  greenExcel: '#579573',
  shadow: '#18274b',

  red1: '#FEEDF0',
  red2: '#FCDBE1',
  red3: '#F9B7C3',
  red4: '#F693A5',
  red5: '#F36F87',
  red6: '#F04B69',
  red7: '#C03C54',
  red8: '#902D3F',
  red9: '#601E2A',
  red10: '#5c0011',
  red11: '#f5222d',

  volcano1: '#FFF2E8',
  volcano2: '#FFD8BF',
  volcano3: '#FFBB96',
  volcano4: '#FF9C6E',
  volcano5: '#FF7A45',
  volcano6: '#FA541C',
  volcano7: '#D4380D',
  volcano8: '#AD2102',
  volcano9: '#871400',
  volcano10: '#610b00',

  orange1: '#fff7e6',
  orange2: '#ffe7ba',
  orange3: '#ffd591',
  orange4: '#ffc069',
  orange5: '#ffa940',
  orange6: '#fa8c16',
  orange7: '#d46b08',
  orange8: '#ad4e00',
  orange9: '#873800',
  orange10: '#612500',

  gold1: '#FFFBE6',
  gold2: '#FFF1B8',
  gold3: '#FFE58F',
  gold4: '#FFD666',
  gold5: '#FFC53D',
  gold6: '#FAAD14',
  gold7: '#D48806',
  gold8: '#AD6800',
  gold9: '#874D00',
  gold10: '#613400',

  yellow1: '#FEFFE6',
  yellow2: '#FFFFB8',
  yellow3: '#FFFB8F',
  yellow4: '#FFF566',
  yellow5: '#FFEC3D',
  yellow6: '#FADB14',
  yellow7: '#D4B106',
  yellow8: '#AD8B00',
  yellow9: '#876800',
  yellow10: '#614700',

  lime1: '#fcffe6',
  lime2: '#f4ffb8',
  lime3: '#eaff8f',
  lime4: '#d3f261',
  lime5: '#bae637',
  lime6: '#a0d911',
  lime7: '#7cb305',
  lime8: '#5b8c00',
  lime9: '#3f6600',
  lime10: '#254000',

  green1: '#E0F7EA',
  green2: '#C1EFD4',
  green3: '#A3E8C0',
  green4: '#84E0AA',
  green5: '#4DD083',
  green6: '#51AD77',
  green7: '#3D825A',
  green8: '#28563B',
  green9: '#142B1E',
  green10: '#092b00',

  cyan1: '#E6FFFB',
  cyan2: '#B5F5EC',
  cyan3: '#87E8DE',
  cyan4: '#5CDBD3',
  cyan5: '#36CFC9',
  cyan6: '#13C2C2',
  cyan7: '#08979C',
  cyan8: '#006D75',
  cyan9: '#00474F',
  cyan10: '#002329',

  geekblue1: '#F0F5FF',
  geekblue2: '#D6E4FF',
  geekblue3: '#ADC6FF',
  geekblue4: '#85A5FF',
  geekblue5: '#597EF7',
  geekblue6: '#2F54EB',
  geekblue7: '#1D39C4',
  geekblue8: '#10239E',
  geekblue9: '#061178',
  geekblue10: '#030852',

  purple1: '#F9F0FF',
  purple2: '#EFDBFF',
  purple3: '#D3ADF7',
  purple4: '#B37FEB',
  purple5: '#9254DE',
  purple6: '#722ED1',
  purple7: '#531DAB',
  purple8: '#391085',
  purple9: '#22075E',
  purple10: '#120338',

  magenta1: '#fff0f6',
  magenta2: '#ffd6e7',
  magenta3: '#ffadd2',
  magenta4: '#ff85c0',
  magenta5: '#f759ab',
  magenta6: '#eb2f96',
  magenta7: '#c41d7f',
  magenta8: '#9e1068',
  magenta9: '#780650',
  magenta10: '#520339',
} as const;

export const productColors = {
  // Product Color Usage
  primary: paletteColors.blue6,
  primaryHover: paletteColors.blue5,
  primaryActive: paletteColors.blue7,
  primaryBackgroundHover: paletteColors.blue1,
  primaryBorder: paletteColors.blue3,

  // Neutral Colors Usage
  title: paletteColors.gray9,
  primaryText: paletteColors.gray8,
  secondaryText: paletteColors.gray7,
  disable: paletteColors.gray6,
  border: paletteColors.gray5,
  dividers: paletteColors.gray4,
  background: paletteColors.gray3,
  tableHeader: paletteColors.gray2,

  // Alert Colors Usage
  alertInfoPrimary: paletteColors.blue6,
  alertInfoPrimaryHover: paletteColors.blue5,
  alertInfoBorder: paletteColors.blue3,
  alertInfoBackground: paletteColors.blue1,

  alertSuccessPrimary: paletteColors.green6,
  alertSuccessPrimaryHover: paletteColors.green5,
  alertSuccessBorder: paletteColors.green3,
  alertSuccessBackground: paletteColors.green1,

  alertWarningPrimary: paletteColors.gold6,
  alertWarningPrimaryHover: paletteColors.gold5,
  alertWarningBorder: paletteColors.gold3,
  alertWarningBackground: paletteColors.gold1,

  alertErrorPrimary: paletteColors.red6,
  alertErrorPrimaryHover: paletteColors.red5,
  alertErrorBorder: paletteColors.red3,
  alertErrorBackground: paletteColors.red1,
};

export const colors = { ...paletteColors, ...productColors, ...experimentColors };
