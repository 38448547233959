import { IAuthConfig, KeyCloakAuthProvider } from 'samolet-oauth2';
import { KeycloakConfig } from 'keycloak-js';
import { LogoType } from 'types/common/logo';
import { IAuthProvider } from '../type';

export class KeycloakProvider extends KeyCloakAuthProvider implements IAuthProvider {
  public logo;

  constructor(config: IAuthConfig & KeycloakConfig) {
    super(config);

    this.logo = {
      logoUrl: '/',
      logoType: LogoType.CONSTRUCTLY,
      projectTitle: 'S.Tender',
      samoletPosition: false,
    };
  }

  getPromisifiedToken(): Promise<string> {
    const token = this.getToken();

    if (token) return Promise.resolve(token);

    return Promise.reject('Keycloak token is invalid');
  }

  updateToken(cb?: () => Promise<void>): void {
    cb?.();
  }
}
