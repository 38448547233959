import { createGlobalStyle, css } from 'styled-components';
import { scrollbar } from '@10d/tend-ui/styling/mixins';
import { colors } from 'constants/colors';

const antStyle = css`
  // INPUT + DATEPICKER
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  .ant-picker-now {
    display: none !important;
  }

  .ant-picker-time-panel-column:after {
    height: 0 !important;
  }

  .ant-picker-datetime-panel + .ant-picker-footer {
    display: none;
  }

  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-input::placeholder,
  .ant-picker-input > input::placeholder,
  .ant-select-selection-placeholder,
  .ant-select-selection-item-content,
  .ant-empty-description,
  [data-testid='tend-ui-button'][disabled],
  .ant-checkbox-disabled + span {
    color: ${colors.grayBlue7};
  }

  // TABS
  .ant-tabs-content-holder {
    overflow-x: auto;
  }

  // MESSAGE
  .ant-message .ant-message-notice-content {
    max-height: 200px;
    overflow-y: auto;
  }

  // POPOVER
  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 8px 22px -6px rgba(24, 39, 75, 0.06),
      0px 14px 64px -4px rgba(24, 39, 75, 0.06);
  }

  .ant-popover-content .ant-popover-inner-content {
    padding: 16px;
  }

  .ant-popover-content .ant-popover-arrow {
    // заменить на <Popover arrow={false} после обновления анта
    display: none;
  }

  .ant-popover-message {
    padding-bottom: 16px;
  }

  // POPCONFIRM
  .ant-popconfirm {
    & .ant-popover-buttons {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    & button {
      height: 32px;
      border-radius: 8px;
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      line-height: 20px;
    }

    & button:first-child {
      border-color: transparent;
      box-shadow: none;

      &:not(:hover) {
        color: ${colors.gray900};
      }
    }

    & .ant-popover-message {
      padding-top: 0;
    }

    & .ant-popover-message-title {
      padding-left: 0;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Museo Sans Cyrl", sans-serif;
    font-weight: 500; // на 400 не менять, Егор не одобряет
    font-style: normal;
  }

  ${antStyle}

  ${scrollbar}
`;
