import styled, { css } from 'styled-components';
import { Spin as DefaultSpin } from 'antd';
import { tendUiColors } from 'constants/colors';
import { FullScreenSpin as UiFullScreenSpin } from './old';

export const FullScreenSpin = styled(UiFullScreenSpin).attrs({ size: 'large' })`
  ${({ $transparentBg }: { $transparentBg?: boolean }) =>
    $transparentBg &&
    css`
      background-color: transparent !important;
    `}
`;

export const Spin = styled(DefaultSpin)``;
export const SpinWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SimpleSpinnerStyled = styled.div<{ size: number }>`
  ${({ size }) => css`
    display: inline-block;
    width: ${size}px;
    height: ${size}px;
    border: 3px solid ${tendUiColors['white800-transparent']};
    border-radius: 50%;
    border-top-color: ${tendUiColors.gray900};
    animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `}
`;
