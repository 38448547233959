import styled from 'styled-components';

const MENU_HEIGHT = 80;
const LAYOUT_CONTENT_PADDING = 16 * 2;

export const Wrapper = styled.div`
  min-height: calc(100vh - ${MENU_HEIGHT}px - ${LAYOUT_CONTENT_PADDING}px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
