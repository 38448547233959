import styled, { css } from 'styled-components';
import DefaultSpin from 'antd/lib/spin';
import { colors } from 'constants/colors';
import { Props } from './Loader.old';

export const SpinWrapper = styled.div<Props>`
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grayBlue1};

  ${props =>
    props.type === 'start' &&
    css`
      .ant-spin-dot {
        max-width: 4em;
        max-height: 4em;
        width: 30vw;
        height: 30vh;

        .ant-spin-dot-item {
          width: 30px;
          height: 30px;
        }
      }
    `}
`;

export const Spin = styled(DefaultSpin)``;

export const FullScreenSpin = styled(Spin)`
  &.ant-spin {
    background-color: #fff9;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;
